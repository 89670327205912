import { photoURL } from "@repo/client";

export const getUserAvatarPath = (userId: string | undefined, src?: string) => {
  if (!userId && !src) return photoURL.DefaultGuestPhotoRelPath;

  const prefix = "https://storytell.ai/";
  // Could be self-hosted, such as "Storytell"s bot image
  if (src?.startsWith(prefix)) {
    return src.slice(prefix.length - 1);
  }
  let bucket = "storytell-avatars";
  switch (RUNNING_ENV) {
    case "local":
    case "preview":
    case "development":
      bucket = "storytell-avatars-development";
      break;
    case "staging":
      bucket = "storytell-uc-assets-staging";
      break;
  }

  return `https://storage.googleapis.com/${bucket}/${userId}`;
};
