import type { Component } from "solid-js";
import { twMerge } from "tailwind-merge";

export const HorizontalDivider: Component<{ class?: string }> = (props) => (
  <div
    aria-hidden
    class={twMerge(
      "pointer-events-none bg-gradient-to-r h-[2px] from-transparent via-violet-300/20 to-transparent",
      props.class,
    )}
  />
);
